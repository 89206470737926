<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13em;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 1.2em" :to="{ path: '/expert_index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 1.2em;color: black">远程会诊</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  远程会诊列表-->
    <div style="width: 95%;margin: auto">
      <div v-for="(item, index) in telMeet" :key="index"
           style="background-color: #FFFFFF;display: flex;margin-top: 1%;">
        <div>
          <img :src="item.url" style="width: 60%;margin-top: 5%;margin-bottom: 5%;margin-left: 2%"/>
        </div>
        <div style="margin-left: -15%;margin-top: 4%">
          <!--会议主题-->
          <div
              style="margin-left: -40%;font-size: 1.8em;font-family: '苹方', serif;font-weight: bolder;margin-top: 10%">
            {{ item.meetTheme }}
          </div>
          <!--参加部门-->
          <div style="margin-left: -40%;margin-top: 20%;font-family: '苹方', serif;font-size: 1.2em;font-weight: bold">
            会议参加部门:&nbsp;<el-tag type="warning" effect="dark">{{ item.meetDepartment }}</el-tag>
          </div>
          <!--  参加专家-->
          <div style="margin-left: -40%;margin-top: 20%;font-family: '苹方', serif;font-size: 1.2em;font-weight: bold">
            会议参加专家:&nbsp;<el-tag type="danger" effect="dark">{{ item.meetExpert }}</el-tag>
          </div>
          <!--  会议时间-->
          <div style="margin-left: -40%;margin-top: 20%;font-family: '苹方', serif;font-size: 1.2em;font-weight: bold">
            会议时间:{{ item.meetTime }}
          </div>
          <!--  删除会议记录-->
          <div style="margin-left: -40%;margin-top: 20%;font-family: '苹方', serif;font-size: 1.2em;font-weight: bold" v-if="GET_USERID === item.userid">
            <el-button type="danger" style="width: 50%" @click="delTelMeet(item.id)">删除记录</el-button>
          </div>
        </div>
      </div>
    </div>
    <!--  新增远程会议-->
    <div>
      <div>
        <el-button type="success" size="mini" style="position:absolute;top: 7%;right: 3%" @click="showDialog()">
          新增远程会诊
        </el-button>
      </div>
      <!--  弹窗-->
      <div>
        <el-dialog v-model="addTelMeetDialog" title="新增远程会诊">
          <el-form>
            <el-form-item label="会议主题">
              <el-input v-model="meetTheme" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="参会部门">
              <el-input v-model="meetDepartment" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="参会专家">
              <el-input v-model="meetExpert" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="会议时间">
              <el-date-picker
                  v-model="meetTime"
                  type="datetime"
                  placeholder="请选择会议时间"
                  format="YYYY-MM-DD hh:mm:ss"
                  value-format="YYYY-MM-DD hh:mm:ss"
              />
            </el-form-item>
          </el-form>
          <el-upload
              ref="upload"
              class="upload-demo"
              action="https://www.cr12cz.cn/czscsms/up/upload"
              :auto-upload="false"
              :data="dataup"
              :on-change="imgchange"
              :on-remove="handleRemove"
              :multiple="false"
              :on-success="upsuccessMeet"
              :limit="1"
          >
            <el-button type="primary" style="margin-left: 35%">请选择会诊截图</el-button>
          </el-upload>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancelAdd()">取消</el-button>
              <el-button type="primary" @click="submitTele()"> 提交 </el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/views/header1";
import {ElMessage, ElMessageBox} from "element-plus";
import {uuid} from "@/utils/time";
import {mapGetters} from "vuex";

export default {
  components: {Header},
  name: "tele_meet_detail",
  inject: ['reload'],
  data() {
    return {
      telMeet: [],
      addTelMeetDialog: false,
      meetTheme: '',
      meetDepartment: '',
      meetExpert: '',
      meetTime: '',
      dataup: {
        file: '',
        userid: ''
      },
      fileList: [],
      uuid: ''
    }
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  created() {
    this.axios.get('/teleconsultation/getAllTeleMeet', (response) => {
      this.telMeet = response.obj;
    })
  },
  methods: {
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    showDialog() {
      this.addTelMeetDialog = true;
    },
    cancelAdd() {
      this.addTelMeetDialog = false;
      this.meetTheme = '';
      this.meetDepartment = '';
      this.meetExpert = '';
      this.meetTime = '';
      this.fileList = [];
    },
    upsuccessMeet(response) {
      this.axios.post("/teleurl/saveTelUrl", (responseAdd) => {
        if (responseAdd.obj) {
          this.addTelMeetDialog = false;
          this.uuid = '';
          this.reload();
          ElMessage({
            message: '提交成功!',
            type: 'success',
          })
        }
      }, {
        uuid: this.uuid,
        url: response.obj
      })
    },
    submitTele() {
      if (this.fileList.length === 0 || this.meetTime === '' || this.meetDepartment === '' | this.meetExpert === ''
      || this.meetTheme === ''){
        ElMessage.error('请检查信息是否填完整！')
      }else{
        this.dataup.userid = this.GET_USERID;
        this.uuid = uuid();
        this.axios.post('/teleconsultation/saveTeleMeet', (response) => {
          if (response.obj) {
            this.$refs.upload.submit();

          } else {
            ElMessage.error("添加失败，请联系科信部!")
          }
        }, {
          uuid: this.uuid,
          theme: this.meetTheme,
          meetDepartment: this.meetDepartment,
          meetExpert: this.meetExpert,
          meetTime: this.meetTime,
          userid: this.GET_USERID,
          username: this.GET_USER
        })
      }
    },

    delTelMeet(val){


      ElMessageBox.confirm(
          '是否确认删除此回复',
          '删除确认',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/teleconsultation/delTeleMeet', (response) => {
              if (response.obj){
                ElMessage({
                  message: '删除成功',
                  type: 'success',
                });
                this.reload();
              }
            },{
              id: val
            })
          })
          .catch(() => {

          })
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>